<template>
  <div class="">
    <h4 class="fs-2 my-5 text-black">Referral Documents Upload</h4>
    <form
      ref="referralDocuments"
      enctype="multipart/form-data"
      class=""
      @submit.prevent="submit"
    >
      <div class="container-fluid">
        <div class="row justify-content-between">
          <div class="col me-4 card-shadow">
            <div class="d-flex flex-column justify-content-between">
              <h4 class="fs-3 text-dark">Legal Documents</h4>
              <span class="fw-light text-gray-600">
                Please upload the letter along with the documents related to it
              </span>
              <div
                v-for="(doc, idx) in documentsLegal"
                :key="doc.display_name"
                class="mt-5"
              >
                <div class="d-flex align-items-center">
                  <span class="me-2 fw-light">{{ doc.display_name }}</span>
                  <img :src="require(`@/assets/svg/${doc.status}.svg`)" />
                </div>
                <div class="d-flex flex-column file-upload-btn flex-1 my-2">
                  <VueUploadComponent
                    ref="uploadLegalDocs"
                    v-model="doc.value"
                    class="file-upload-btn border rounded-2 w-50 p-2 px-3"
                    post-action="/upload/post"
                    :multiple="true"
                    :size="1024 * 1024 * 10"
                    :name="doc.display_name"
                    @input-file="doc.status = 'included'"
                  >
                    <div
                      class="d-flex justify-content-center align-item-center"
                    >
                      <img
                        src="@/assets/svg/folder.svg"
                        class="me-3 icon-gray"
                      />
                      {{ doc.button_display_text }}
                    </div>
                  </VueUploadComponent>

                  <div v-if="doc.value.length" class="mt-2">
                    <div
                      v-for="(files, fileIdx) in doc.value"
                      :key="files.name ? files.name : files.file_name"
                      style="font-size: 13px"
                      class="d-flex align-items-center"
                    >
                      <button
                        class="btn border-0 bg-transparent text-danger btn-sm"
                        @click="deleteLegalFile(idx, fileIdx)"
                      >
                        x
                      </button>

                      <div>
                        <a
                          v-if="files.file_name"
                          :href="files.file"
                          target="_blank"
                          :download="files.file_name"
                        >
                          {{ files.file_name }}
                        </a>
                        <span v-else class="fw-light">{{ files.name }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="col card-shadow d-flex flex-column justify-content-between"
          >
            <h4 class="fs-3 text-dark">Others</h4>
            <div class="">
              <div class="d-flex align-items-center">
                <span class="me-2 fw-light">Other Documents</span>
              </div>
              <div
                class="d-flex align-items-center file-upload-btn flex-1 my-2"
              >
                <VueUploadComponent
                  ref="uploadAdditionalDocs"
                  v-model="documentsOther.value"
                  class="file-upload-btn border rounded-2 p-2"
                  post-action="/upload/post"
                  :multiple="true"
                  :size="1024 * 1024 * 10"
                  name="otherDocuments"
                >
                  <div
                    class="d-flex justify-content-center align-item-center px-5"
                  >
                    <img src="@/assets/svg/folder.svg" class="me-2 icon-gray" />
                    Upload
                  </div>
                </VueUploadComponent>
              </div>

              <div v-if="documentsOther.value.length" class="mt-2">
                <div
                  v-for="(files, fileIdx) in documentsOther.value"
                  :key="files.name ? files.name : files.file_name"
                  style="font-size: 13px"
                  class="d-flex align-items-center"
                >
                  <button
                    class="btn border-0 bg-transparent text-danger btn-sm"
                    @click="deleteOtherFile(fileIdx)"
                  >
                    x
                  </button>

                  <div>
                    <a
                      v-if="files.file_name"
                      :href="files.file"
                      target="_blank"
                      :download="files.file_name"
                    >
                      {{ files.file_name }}
                    </a>
                    <span v-else class="fw-light">{{ files.name }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card-shadow d-flex flex-column">
        <h4 class="mb-5 fs-3 text-dark">Patient's Medical Documents</h4>
        <div
          id="LawyerReferralDocuments"
          ref="LawyerReferralDocuments"
          class="row mx-auto"
          enctype="multipart/form-data"
          @submit.prevent="submit"
        >
          <div
            v-for="(doc, idx) in documentsMedical"
            :key="doc.display_name"
            class="col-6 mb-5"
          >
            <div class="d-flex align-content-center">
              <span>{{ doc.display_name }}</span>
              <img
                :src="require(`@/assets/svg/${doc.status}.svg`)"
                class="ms-2"
              />
            </div>

            <div class="d-flex justify-content-start align-items-center my-3">
              <div class="me-3">
                <!-- upload button -->
                <div
                  class="d-flex justify-content-center align-items-center file-upload-btn"
                >
                  <VueUploadComponent
                    :ref="doc.display_name"
                    v-model="doc.value"
                    class="file-upload-btn"
                    post-action="/upload/post"
                    :multiple="true"
                    :size="1024 * 1024 * 10"
                    :name="doc.display_name"
                    :disabled="doc.status === 'unavailable' ? true : false"
                    @input-file="doc.status = 'included'"
                  >
                    <div
                      class="px-5 py-2 upload-btn border rounded-2 d-flex justify-content-center align-items-center"
                      :class="
                        doc.status === 'unavailable'
                          ? 'border-gray-400 text-gray-400'
                          : doc.status === 'waiting'
                          ? 'border-warning text-waiting enable-pointer'
                          : 'border-success text-success enable-pointer'
                      "
                    >
                      <img
                        src="@/assets/svg/folder.svg"
                        class="me-2"
                        :class="{
                          'd-none': doc.status === 'unavailable',
                          'icon-pending': doc.status === 'waiting',
                          'icon-success': doc.status === 'included',
                        }"
                      />
                      {{ doc.status === 'unavailable' ? '-' : 'Upload' }}
                    </div>
                  </VueUploadComponent>
                </div>
              </div>
              <!-- not available button -->
              <div>
                <div>
                  <input
                    :id="`${doc.display_name + '-check-btn'}`"
                    type="checkbox"
                    class="btn-check border-1 px-3 py-2 bg-transparent"
                    autocomplete="off"
                    @change="setUnavailable(idx)"
                  />
                  <label
                    class="btn border-1 border d-flex justify-content-center align-items-center px-3 py-2"
                    :for="`${doc.display_name + '-check-btn'}`"
                    :class="
                      doc.status === 'unavailable'
                        ? 'border-gray-600 text-gray-600'
                        : 'border-gray-400 text-gray-400'
                    "
                  >
                    <div
                      :class="
                        doc.status === 'unavailable' ? 'square' : 'empty-square'
                      "
                    ></div>
                    <span class="ms-2">Not Available</span>
                  </label>
                </div>
              </div>
            </div>
            <div v-if="doc.value.length > 0" class="mt-2">
              <div
                v-for="(files, fileIdx) in doc.value"
                :key="files.name ? files.name : files.file_name"
                style="font-size: 13px"
                class="d-flex align-items-center"
              >
                <button
                  type="button"
                  class="btn border-0 bg-transparent text-danger btn-sm"
                  @click="deleteFile(idx, fileIdx)"
                >
                  x
                </button>

                <div>
                  <span v-if="files.file_name">
                    {{ files.file_name }}
                  </span>
                  <span v-else>
                    {{ files.name ?? files.display_name }}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="align-self-end flex-fill">
            <div class="d-flex justify-content-center">
              <button
                type="submit"
                class="btn btn-success rounded-3 text-white w-25"
              >
                <span>Submit</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script setup>
import VueUploadComponent from 'vue-upload-component'
import { ref } from 'vue'
import { useStore } from 'vuex'

import { useRouter, useRoute } from 'vue-router'

// VueRouter
const router = useRouter()
const route = useRoute()

// Vuex
const store = useStore()

// Medical Documents only
const documentsMedical = ref([])

// Temporary data
documentsMedical.value = [
  {
    display_name: 'GP Notes',
    id: 1,
    value: [],
    status: 'waiting',
  },
  {
    display_name: 'Hospital Record',
    id: 2,
    value: [],
    status: 'waiting',
  },
  {
    display_name: 'Investigation reports',
    id: 3,
    value: [],
    status: 'waiting',
  },
  {
    display_name: 'Medicolegal Documents',
    id: 5,
    value: [],
    status: 'waiting',
  },
  {
    display_name: 'Specialist Documents',
    id: 7,
    value: [],
    status: 'waiting',
  },
  {
    display_name: 'Surgical Note',
    id: 8,
    value: [],
    status: 'waiting',
  },
]

// Status Manipulation
// Only for medical documents
function setUnavailable(idx) {
  if (documentsMedical.value[idx].status === 'unavailable') {
    documentsMedical.value[idx].status = 'waiting'
  } else documentsMedical.value[idx].status = 'unavailable'
}

// Remove one of the file in medical documents
function deleteFile(doc_index, file_index) {
  documentsMedical.value[doc_index].value.splice(file_index, 1)

  // Set back to default status if file list is empty
  if (documentsMedical.value[doc_index].value.length === 0) {
    documentsMedical.value[doc_index].status = 'waiting'
  }
}

// Legal documents only
const documentsLegal = ref([])

// Temporary data
documentsLegal.value = [
  {
    display_name: 'Briefing Letter',
    id: 0,
    value: [],
    button_display_text: 'Upload of referral legal letter',
    status: 'waiting',
  },
]

// Remove one of the file in legal document
function deleteLegalFile(doc_index, file_index) {
  documentsLegal.value[doc_index].value.splice(file_index, 1)

  // Set back to default status if file list is empty
  if (documentsLegal.value[doc_index].value.length === 0) {
    documentsLegal.value[doc_index].status = 'waiting'
  }
}

// Other documents only
const documentsOther = ref({})
documentsOther.value = {
  display_name: 'Other Document',
  id: 0,
  value: [],
}

// Remove one of the file in other documents
function deleteOtherFile(file_index) {
  documentsOther.value.value.splice(file_index, 1)
}

// Update patient document's state

const referralDocuments = ref(null)
function submit() {
  store.state.referralDocuments = new FormData(referralDocuments.value)

  return router.push({
    name: 'lawyer-referral-created',
    params: { id: route.params.id },
  })
}
</script>

<style lang="scss">
.icon-gray {
  filter: brightness(0) saturate(100%) invert(99%) sepia(13%) saturate(0%)
    hue-rotate(142deg) brightness(82%) contrast(86%);
}

.card-shadow {
  align-items: top;
  padding: 30px;
  margin-bottom: 30px;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

#LawyerReferralDocuments {
  & label,
  .download-btn {
    font-size: 1rem;
    font-weight: 400;

    outline: 0;
    user-select: none;
    min-width: 165.7px;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  & .upload-btn {
    min-width: 165.7px;

    & input:disabled {
      cursor: none;
    }
  }
}

.square {
  height: 16px;
  width: 16px;
  border-radius: 3px;
  background-color: #6c757d;
}

.empty-square {
  height: 16px;
  width: 16px;
  border: 1px solid #c4c4c4;
  border-radius: 3px;
}

.enable-pointer {
  cursor: pointer;
}
</style>
